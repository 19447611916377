import React, { useState, Fragment } from "react";
import moment from "moment";
import { Link, browserHistory } from 'react-router';
import { Grid, Dialog, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormsDropDown from './FormsDropDown';
import { displayDateFormatShort } from "../../constants";
import { CircularProgress } from "../../shared_elements";
const FormWrapper = ({ updateFormStatusFn, exportReportBtn, toggleDownloadLogsFn, showSaveBtn, project, formInfo, hideAction=false}) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const handleClose = () => {
        setOpen(false);
        setValue('');
    };
    const onChange = (value) => {
        setValue(value);
    };
    return (
        <Fragment>
            <div className="form-wrapper-head">
                <Grid container spacing={1} alignItems="center">
                    <Grid item md={6} xs={12}>
                        <ul className="list-inline flex-centered">
                            <li className="list-inline-item">
                                <Link to={`/technical/project/view/${formInfo?.project?.slug}`}><ArrowBackIcon color="primary" fontSize="small" /></Link>
                            </li>
                            <li className="list-inline-item">
                                <div className="project-detail flex-centered">
                                    <div className="project-percentage">
                                        <CircularProgress value={formInfo?.current_form?.progress ? formInfo?.current_form?.progress : 0} />
                                    </div>
                                    <div className="project-card">
                                        <h3>
                                            <span onClick={() => browserHistory.push(`/technical/project/view/${formInfo?.project?.slug}`)}>{formInfo?.project?.name}</span>
                                        </h3>
                                        <p>
                                            <span className='project-status'>
                                                <div className={`${formInfo?.current_form.status.label.toLowerCase()}`} > 
                                                    {formInfo?.current_form.status.label}
                                                </div>
                                            </span>
                                            {formInfo?.project.start_date || formInfo?.project.expected_end_date ?
                                            <span className="project-dates">{formInfo?.project.start_date?moment(formInfo?.project.start_date).format(displayDateFormatShort):''} - {formInfo?.project.expected_end_date ? moment(formInfo?.project.expected_end_date).format(displayDateFormatShort):''}</span>
                                            :null}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="list-inline-item">
                                <div className="asset-form-card">
                                    <h4>
                                        {formInfo?.asset?.asset_type === 1 ?
                                            <Fragment>
                                                MSN {formInfo?.asset.msn} ({formInfo?.asset.aircraft_type})
                                            </Fragment> : null
                                        }
                                        {formInfo?.asset?.asset_type === 2 ?
                                            <Fragment>
                                                ESN {formInfo?.asset.esn} ({formInfo?.asset.engine_type})
                                            </Fragment> : null
                                        }
                                        {formInfo?.asset?.asset_type === 3 ?
                                            <Fragment>
                                                APU {formInfo?.asset.serial_number}
                                            </Fragment> : null
                                        }
                                        {formInfo?.asset?.asset_type === 4 ?
                                            <Fragment>
                                                Landing Gear {formInfo?.asset.serial_number}
                                            </Fragment> : null
                                        }
                                        {formInfo?.asset?.asset_type === 5 ?
                                            <Fragment>
                                                Propellar {formInfo?.asset.serial_number}
                                            </Fragment> : null
                                        }
                                    </h4>
                                    <p>
                                        <FormsDropDown formInfo={formInfo} />
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ul className="list-inline form-action-btn-grp">
                            <li className="list-inline-item" style={{ verticalAlign: 'middle',marginRight:"15px" }}>
                                <h5 style={{ fontSize: '13px',fontWeight:'400'  }}>Last Activity</h5>
                                <h3 style={{ fontSize: '15px' ,fontFamily:'Conv_IBMPlexSans-Medium',fontWeight:'500'}}>{moment(formInfo?.current_form.updated_at).format(displayDateFormatShort)}</h3>
                            </li>
                            <li className="list-inline-item btn-grp">
                                {showSaveBtn && formInfo?.current_form && formInfo?.current_form.permissions.can_save ?
                                    <Button color="primary" variant="contained" size="small">Save</Button> : null
                                }
                                {!hideAction ?
                                    <>
                                        {formInfo?.current_form && formInfo?.current_form.permissions.can_submit ? <Button onClick={() => updateFormStatusFn({ status: 5 })} color="primary" variant="contained" size="small">Submit</Button> : null}
                                        {formInfo?.current_form && formInfo?.current_form.permissions.can_accept ? <Button onClick={() => updateFormStatusFn({ status: 6 })} color="primary" variant="contained" size="small" style={{ marginRight: '10px' }}>Accept</Button> : null}
                                        {formInfo?.current_form && formInfo?.current_form.permissions.can_accept ? <Button onClick={() => setOpen(true)} color="secondary" variant="outlined" size="small">Reject</Button> : null}
                                        {formInfo?.current_form && formInfo?.current_form.permissions.can_publish ? <Button onClick={() => updateFormStatusFn({ status: 7 })} color="primary" variant="contained" size="small">Publish</Button> : null}
                                    </>:null
                                }
                            </li>
                            {exportReportBtn ?
                                <li className="list-inline-item">
                                    {exportReportBtn}
                                </li> : null
                            }
                        </ul>
                    </Grid>
                </Grid>
            </div>
            {open ?
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <div style={{ width: '380px' }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="remarks"
                                fullWidth
                                multiline
                                label='Reason of Rejection'
                                rows="4"
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={value && value.trim().length ? false : true} variant="contained" color="secondary" onClick={() => { handleClose(); updateFormStatusFn({ status: 3, remarks: value }) }}>
                            Reject
                        </Button>
                    </DialogActions>
                </Dialog> : null
            }
        </Fragment>
    )
}
export default FormWrapper;