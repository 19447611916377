import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Checkbox, Radio, RadioGroup, FormControl, FormLabel,  FormControlLabel, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { GoogleLocationField } from '../../Elements'
import AssetInspectionDate from './AssetInspectionDate';
import { PageLoader } from '../../../shared_elements';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus } from '../../../utils_v2'
import { regexConstants } from '../../../constants/regEx';
import { fieldValidation } from '../../../utils_v2/formValidation';
import { alpWorkOrder,errorCode } from '..';
const assetTypeInfo = {
  3: {label:'APU',key:'apu',params:'apu_id', errorMsg:'Please select APU'},
  4: {label: 'Landing Gear',key:'lg',params:'lg_id', errorMsg:'Please select Landing Gear'},
  5: {label: 'Propeller',key: 'propeller',params:'propeller_id', errorMsg:'Please select Propeller'}
}
const ALPCRU = ({modal, assetType, toggleModalFn, id, project, params, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [workOrder, setWorkOrder] = useState({});
  const [forms, setForms] = useState([]);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [assets, setAssets] = useState([])
  const [error, setError] = useState({});
  useEffect(() => {
    if(id){
      globalGetService(`technical/project/${params.project_slug}/workorder/${id}/`)
      .then(response => {
        if(checkApiStatus(response)){         
          setWorkOrder(response.data.data)
        }
      });
    }else{
      setWorkOrder({
        ...alpWorkOrder,
        start_date: project.start_date,
        expected_end_date: project.expected_end_date,
      });
    }
    globalGetService('technical/forms/',{apu_type:'True', lg_type:'True'})
    .then(response => {
      if(checkApiStatus(response)){
        setForms(response.data.data)
      }
    });

    globalGetService('technical/inspection-types/')
    .then(response => {
      if(checkApiStatus(response)){
        setInspectionTypes(response.data.data)
      }
    });

    globalGetService('console/list/', {switcher:true, asset_type:assetType})
    .then(response => {
      if(checkApiStatus(response)){
        setAssets(response.data.data.asset)
      }
    });
  },[]);
  const resetErrorKey = (key) => {
    setError({
      ...error,
      [key]:''
    })
  }
  const onFieldChange = (event, keyParam, value) => {
    setWorkOrder({
      ...workOrder,
      [keyParam]:value
    })
  }
  const getWorkOrderDetail = (asset) => {
    globalGetService(`technical/${assetTypeInfo[assetType].key}/${asset.slug}/work-order-details/`)
    .then(response => {
      const { apu_type_id, asset_type, id, serial_number, slug, ...assetObj } =  response.data.data;
      if(checkApiStatus(response)){
        setWorkOrder({...workOrder, ...assetObj})
      }
    });
  }
  const onAddEditWorkOrder = () => {
    // Apply the Form Validation
    let validationInputs = {
      asset: workOrder[assetTypeInfo[assetType].params] ? '':assetTypeInfo[assetType].errorMsg,
      start_date:workOrder.start_date ? moment(workOrder.start_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date' :'Please enter Start Date',
      expected_end_date:workOrder.expected_end_date ? moment(workOrder.expected_end_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date' :'Please enter End Date',
      status_as_of:workOrder.status_as_of ? moment(workOrder.status_as_of, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      records_inspection_on_start_date:workOrder.records_inspection_on_start_date ? moment(workOrder.records_inspection_on_start_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      records_inspection_on_end_date:workOrder.records_inspection_on_end_date ? moment(workOrder.records_inspection_on_end_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      physical_inspection_on_start_date:workOrder.physical_inspection_on_start_date ? moment(workOrder.physical_inspection_on_start_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
      physical_inspection_on_end_date:workOrder.physical_inspection_on_end_date ? moment(workOrder.physical_inspection_on_end_date, backendDateFormat, true).isValid() ? '':'Please enter valid Date':'',
    }
    // start date should be less then expected end date, when we have both the date
    if(workOrder.start_date && workOrder.expected_end_date){
      if(moment(workOrder.start_date).isAfter(moment(workOrder.expected_end_date))){
        validationInputs['start_date'] = 'Start date should be less then expected end date';
      }

      // start date and end date should be between project start date and end date
      if(moment(workOrder.start_date).isBefore(moment(project.start_date)) || moment(workOrder.start_date).isAfter(moment(project.expected_end_date))){
        validationInputs['start_date'] = 'Start date should be between project start date and end date';
      }
      if(moment(workOrder.expected_end_date).isBefore(moment(project.start_date)) || moment(workOrder.expected_end_date).isAfter(moment(project.expected_end_date))){ 
        validationInputs['expected_end_date'] = 'Expected end date should be between project start date and end date';
      }
    }

    // if(workOrder.physical_inspection_on_start_date){
    //   // physical inspection start and physical inspection end date should be between project start date and end date
    //   if(moment(workOrder.physical_inspection_on_start_date).isBefore(moment(project.start_date)) || moment(workOrder.physical_inspection_start_date).isAfter(moment(project.expected_end_date))){
    //     validationInputs['physical_inspection_on_start_date'] = 'Physical inspection start date should be between project start date and end date';
    //   }
    // }
    // if(workOrder.physical_inspection_on_end_date){
    //   if(moment(workOrder.physical_inspection_on_end_date).isBefore(moment(project.start_date)) || moment(workOrder.physical_inspection_end_date).isAfter(moment(project.expected_end_date))){
    //     validationInputs['physical_inspection_on_end_date'] = 'Physical inspection end date should be between project start date and end date';
    //   }
    // }

    // physical inspection start date should be less then physical inspection end date, when we have both the date
    if(workOrder.physical_inspection_on_start_date && workOrder.physical_inspection_on_end_date){
      if(moment(workOrder.physical_inspection_on_start_date).isAfter(moment(workOrder.physical_inspection_on_end_date))){
        validationInputs['physical_inspection_on_start_date'] = 'Physical inspection start date should be less then physical inspection end date';
      }
    }
    if(workOrder.records_inspection_on_start_date){
      // Records inspection start and Records inspection end date should be between project start date and end date
      if(moment(workOrder.records_inspection_on_start_date).isBefore(moment(project.start_date)) || moment(workOrder.records_inspection_on_start_date).isAfter(moment(project.expected_end_date))){
        validationInputs['records_inspection_on_start_date'] = 'Records inspection start date should be between project start date and end date';
      }
    }
    if(workOrder.records_inspection_on_end_date){
      if(moment(workOrder.records_inspection_on_end_date).isBefore(moment(project.start_date)) || moment(workOrder.records_inspection_on_end_date).isAfter(moment(project.expected_end_date))){
        validationInputs['records_inspection_on_end_date'] = 'Records inspection end date should be between project start date and end date';
      }
    }
    // Records inspection start date should be less then Records inspection end date, when we have both the date
    if(workOrder.records_inspection_on_start_date && workOrder.records_inspection_on_end_date){
      if(moment(workOrder.records_inspection_on_start_date).isAfter(moment(workOrder.records_inspection_on_end_date))){
        validationInputs['records_inspection_on_start_date'] = 'Records inspection start date should be less then Records inspection end date';
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      setLoading(true);
      if(id){
        globalPutService(`technical/project/${params.project_slug}/workorder/${workOrder.slug}/`, workOrder)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            getResponseBack();
          }else{
            enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
          }
        })
      }else{
        globalPostService(`technical/project/${params.project_slug}/workorder/`, workOrder)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            getResponseBack();
          }else{
            enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
          }
        })
      }
    }else{
      setError(validationInputs)
    }
  }
  return(
    <Dialog
      maxWidth="md"
      open={modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
      className='project-management-modal add-edit-work-order-modal'
    >
      <DialogTitle id="scroll-dialog-title">
        {id ? 'Edit':'Add'} {assetTypeInfo[assetType].label}
      </DialogTitle>
      <DialogContent dividers={true}>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                disabled={id ? true:false}
                options = {assets}
                getOptionLabel={option => option.serial_number}
                id={assetTypeInfo[assetType].params}
                value={assets.length && workOrder[assetTypeInfo[assetType].params] ? assets.find(item => item.slug === workOrder[assetTypeInfo[assetType].params]) :null}
                onChange={(e,value) => {getWorkOrderDetail(value);resetErrorKey('asset')}}
                renderInput={params => <TextField required {...params} error={error.asset ? true:false} helperText={error.asset ? error.asset:''} label={assetTypeInfo[assetType].label} margin="normal" fullWidth variant='outlined' InputLabelProps={{shrink: true}} />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    required
                    id="start_date"
                    label="Start Date"
                    format={fieldDateFormat}
                    fullWidth
                    clearable={true}
                    minDate={moment(project.start_date)}
                    maxDate={moment(project.expected_end_date)}
                    InputLabelProps={{shrink: true}}
                    helperText={error.start_date ? error.start_date:''}
                    value={workOrder.start_date ? workOrder.start_date:null}
                    onChange={(data, value) => {onFieldChange(value, 'start_date', data ? moment(data).format(backendDateFormat):data); resetErrorKey('start_date')}}
                    inputVariant='outlined'
                  />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    required
                    id="expected_end_date"
                    label="Expected End Date"
                    format={fieldDateFormat}
                    fullWidth
                    clearable={true}
                    InputLabelProps={{shrink: true}}
                    minDate={moment(project.start_date)}
                    maxDate={moment(project.expected_end_date)}
                    error={error.expected_end_date ? true:false}
                    helperText={error.expected_end_date ? error.expected_end_date:''}
                    value={workOrder.expected_end_date ? workOrder.expected_end_date:null}
                    onChange={(data, value) => {onFieldChange(value, 'expected_end_date', data ? moment(data).format(backendDateFormat):data); resetErrorKey('expected_end_date')}}
                    inputVariant='outlined'
                  />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="lease_start_date"
                    label="Lease Start Date"
                    format={fieldDateFormat}
                    fullWidth
                    disableFuture={true}
                    clearable={true}
                    InputLabelProps={{shrink: true}}
                    value={workOrder.lease_start_date ? workOrder.lease_start_date:null}
                    onChange={(data, value) => {onFieldChange(value, 'lease_start_date', data ? moment(data).format(backendDateFormat):data);}}
                    inputVariant='outlined'
                  />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={3} >
              <TextField
                id="tsn"
                label="Workorder No"
                fullWidth
                margin="normal"
                value={workOrder.work_order_number ? workOrder.work_order_number:''}
                InputLabelProps={{shrink: true}}
                inputProps={{maxLength: 10}}
                onChange={(e) => onFieldChange(e, 'work_order_number', e.target.value)}
                variant='outlined'
              />
           </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="tsn"
                label="TSN"
                fullWidth
                margin="normal"
                value={workOrder.tsn ? workOrder.tsn:''}
                InputLabelProps={{shrink: true}}
                inputProps={{maxLength: 10}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value) : e.preventDefault()}}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                id="csn"
                label="CSN"
                fullWidth
                margin="normal"
                value={workOrder.csn ? workOrder.csn:''}
                InputLabelProps={{shrink: true}}
                inputProps={{maxLength: 10}}
                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value) : e.preventDefault()}}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {workOrder && Object.keys(workOrder).length ?
                <GoogleLocationField 
                  onFieldChange={onFieldChange}
                  location={workOrder.location ? workOrder.location:''}
                />:null
              }
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    disabled
                    id="date_of_manufacture"
                    label="Date of Manufacture"
                    format={fieldDateFormat}
                    fullWidth
                    disableFuture={true}
                    clearable={true}
                    InputLabelProps={{shrink: true}}
                    error={error.date_of_manufacture ? true:false}
                    helperText={error.date_of_manufacture ? error.date_of_manufacture:''}
                    value={workOrder.date_of_manufacture ? workOrder.date_of_manufacture:null}
                    onChange={(data, value) => {onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat):data);}}
                    inputVariant='outlined'
                  />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="status_as_of"
                    label="Status As Of"
                    format={fieldDateFormat}
                    fullWidth
                    disableFuture={true}
                    clearable={true}
                    InputLabelProps={{shrink: true}}
                    error={error.status_as_of ? true:false}
                    helperText={error.status_as_of ? error.status_as_of:''}
                    value={workOrder.status_as_of ? workOrder.status_as_of:null}
                    onChange={(data, value) => {onFieldChange(value, 'status_as_of', data ? moment(data).format(backendDateFormat):data); resetErrorKey('status_as_of')}}
                    inputVariant='outlined'
                  />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options = {inspectionTypes}
                getOptionLabel={option => option.name}
                id="inspection_types"
                multiple
                value={inspectionTypes.length && workOrder.inspection_types && workOrder.inspection_types.length ? inspectionTypes.filter(inspection => workOrder.inspection_types.includes(inspection.id)):[]}
                onChange={(e, value) => onFieldChange(e, 'inspection_types', value ? value.map(inspection => inspection.id) :value)}
                renderInput={params => <TextField {...params} error={error.inspection_types ? true:false} helperText={error.inspection_types ? error.inspection_types:''} label="Inspection Types" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options = {forms}
                getOptionLabel={option => option.name}
                id="forms"
                multiple
                disableCloseOnSelect={true}
                value={forms.length && workOrder.forms && workOrder.forms.length ? forms.filter(form => workOrder.forms.includes(form.id)):[]}
                onChange={(e, value) => onFieldChange(e, 'forms', value ? value.map(form => form.id) :value)}
                renderInput={params => <TextField {...params} error={error.forms ? true:false} helperText={error.forms ? error.forms:''} label="Forms" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="line_number"
                label="Line Number"
                fullWidth
                margin="normal"
                value={workOrder.line_number ? workOrder.line_number:''}
                InputLabelProps={{shrink: true}}
                onChange={(e) => onFieldChange(e, 'line_number', e.target.value)}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="operator_storage_facility"
                label="Operator Storage Facility"
                fullWidth
                margin="normal"
                value={workOrder.operator_storage_facility ? workOrder.operator_storage_facility:''}
                InputLabelProps={{shrink: true}}
                onChange={(e) => onFieldChange(e, 'operator_storage_facility', e.target.value)}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="aoc_regulation"
                label="AOC Regulation"
                fullWidth
                margin="normal"
                value={workOrder.aoc_regulation ? workOrder.aoc_regulation:''}
                InputLabelProps={{shrink: true}}
                onChange={(e) => onFieldChange(e, 'aoc_regulation', e.target.value)}
                variant='outlined'
              />
            </Grid>
            <AssetInspectionDate
              workOrder={workOrder}
              onFieldChange={onFieldChange}
              error={error}
              resetErrorKey={resetErrorKey}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} color="primary" onClick={toggleModalFn} size="small">Cancel</Button>
        <Button disabled={isLoading} variant="contained" onClick={onAddEditWorkOrder} color="primary" size="small">
          { isLoading ? <CircularProgress size={20} /> : 'Save' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default withRouter(ALPCRU);
