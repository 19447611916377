import React, { useState } from "react"
import { TableRow, TableCell, Tooltip, IconButton, Dialog, } from "@material-ui/core"
import { withRouter, browserHistory } from "react-router"
import moment from 'moment'
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from "@material-ui/icons/Delete"
import { displayDateFormatShort } from "../../../constants"
const TaskList = ({params, item, onRemoveTasks,filter}) => {
  const redirectDetail = () => {
    let location = browserHistory.getCurrentLocation();
    location = {
      ...location,
      pathname:`/technical/project/${params.project_slug}/task/${item.id}`,
      query: filter
    }
    browserHistory.push(location);
  }
  return (
    <TableRow style={{cursor:'pointer'}} onClick={ () => redirectDetail()} hover tabIndex={-1}>
      <TableCell>
        <div className="multiline-clamp" style={{width:'140px'}}>
          {item.name}
        </div>
      </TableCell>
      <TableCell>
        {item.status ?
          <span className={`milestone-status ${item.status.label.toLowerCase()}`}>
            <span className="flex-centered">
              {item.status.label}
            </span>
          </span>:null
        }
      </TableCell>
      <TableCell>
        <div className="singleline-clamp" style={{width:'120px'}}>
          {item.owner ? item.owner.name : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.start_date ?  moment(item.start_date).format(displayDateFormatShort):'--'}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.end_date ? moment(item.end_date).format(displayDateFormatShort):'--'}
        </div>
      </TableCell>
      <TableCell>
        <div className="singleline-clamp" style={{width:'80px'}}>
          {item.priority ? item.priority.label : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div className="singleline-clamp" style={{width:'120px'}}>
          {item.severity ? item.severity.label : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div className="singleline-clamp" style={{width:'120px'}}>
          {item.follower ? item.follower.name : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'130px'}}>
          {item.project_stage ? item.project_stage.name : "--"} 
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'130px'}}>
          {item.project_milestone ? item.project_milestone.name : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}> 
          {item.work_order ? item.work_order.unique_name : "--"} 
        </div>
      </TableCell>
      <TableCell>
        <div className="singleline-clamp" style={{width:'80px'}}>
          {item.form ? item.form.name : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'120px'}}>
          {item.created_by.name ? item.created_by.name : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'100px'}}>
          {item.created_at ? moment(item.created_at).format(displayDateFormatShort) : "--"}
        </div>
      </TableCell>
      <TableCell>
        <div style={{width:'70px'}}>
          <Tooltip title="View/Edit">
            <IconButton size="small" color="primary" onClick={ () => browserHistory.push(`/technical/project/${params.project_slug}/task/${item.id}`)} >
              <VisibilityIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="small" color="primary" onClick={(e) => {e.preventDefault();e.stopPropagation(); onRemoveTasks(item)}} >
              <DeleteIcon color='secondary' fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  )
}
export default withRouter(TaskList)
