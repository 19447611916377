import React, { useEffect,useState } from "react";
import { useSnackbar } from 'notistack';
import { Paper, Grid, Button } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { BudgetWrapper, BudgetChartByCategory, BudgetExpenseChartByCategory, BudgetExpenseBarChart } from '../components';
import { PageLoader } from '../../../shared_elements';
import { globalGetService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
const BudgetAnalytics = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [allocationLoader, setAllocationLoader] = useState(true);
    const [expenseLoader, setExpenseLoader] = useState(true);
    const [compareLoader, setCompareLoader] = useState(true);
    const [project, setProject] = useState({});
    const [categoryVsAllocation, setCategoryVsAllocation] = useState([]);
    const [categoryVsExpense, setCategoryVsExpense] = useState([]);
    const [allocationVsExpense, setAllocationVsExpense] = useState({});
    useEffect(() => {
        getProjectDetail();
        getCategoryVsAllocation();
        getCategoryVsExpense();
        getAllocationVsExpense();
        trackActivity('Expense Management', {
            event_type: 'Page Visited',
            page_title: 'Budget Analytics',
          });
    },[]);
    const getCategoryVsAllocation = () => {
        setAllocationLoader(true);
        globalGetService(`technical/project/${params.project_slug}/budgets/analytics/categoryVsAllocation/`)
        .then(response => {
            setAllocationLoader(false);
            if(checkApiStatus(response)){
                setCategoryVsAllocation(response.data.data.series)
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const getCategoryVsExpense = () => {
        setExpenseLoader(true);
        globalGetService(`technical/project/${params.project_slug}/budgets/analytics/categoryVsExpense/`)
        .then(response => {
            setExpenseLoader(false);
            if(checkApiStatus(response)){
                setCategoryVsExpense(response.data.data.series)
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const getAllocationVsExpense = () => {
        setCompareLoader(true);
        globalGetService(`technical/project/${params.project_slug}/budgets/analytics/allocationVsExpense/`)
        .then(response => {
            setCompareLoader(false);
            if(checkApiStatus(response)){
                setAllocationVsExpense(response.data.data)
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const getProjectDetail = () => {
        globalGetService(`technical/project/${params.project_slug}/`,{fields:'work_orders,archive_status,id,name,status,start_date,expected_end_date,currency,budget_amount'})
        .then(response => {
            if(checkApiStatus(response)){
                setProject(response.data.data);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    console.log('categoryVsAllocation', categoryVsAllocation)
    return (
        <section className='projects-management-apps projects-management-section'>
            <BudgetWrapper project={project} getResponseBack={() => getProjectDetail()}>
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            { allocationLoader ? <Skeleton variant="rect" height={300} /> : <BudgetChartByCategory project={project} series={categoryVsAllocation} /> }
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            { expenseLoader ? <Skeleton variant="rect" height={300} /> : <BudgetExpenseChartByCategory project={project} series={categoryVsExpense} /> }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            { compareLoader ? <Skeleton variant="rect" height={300} /> :
                            <BudgetExpenseBarChart project={project} allocationVsExpense={allocationVsExpense} />}
                        </Grid>
                    </Grid>
                </div>                
            </BudgetWrapper>
        </section>
    );
}
export default BudgetAnalytics;