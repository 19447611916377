import FormTemplates from './form_templates/containers'
import ProjectListing from './projects/containers'
import ProjectDetail from './projects/containers/View'
import OprForm from './projects/containers/OprForm'

import GeneralPictures from './t001/containers';
import DataPlates from './t002/containers';
import DocumentsChecklist from './t003/containers';
import ObservationChecklist from './t004/containers';
import Contacts from './t006/containers';
import PictorialFindings from './t007/containers';
import Robberies from './t009/containers';
import TechnicalSpecification from './t005/containers';
import ProblemResolutionSheet from './t011/containers'
import PRSIssueDetail from './t011/containers/PRSIssueDetail'
import T014Nav from './t014/containers';

import BoreScopeInspection from './te001/containers';
import PowerPlantInventories from './te002/containers';
import GapReports from './te003/containers';
import BuildStandardEvaluation from './te004/containers'
import TableInspection from './te005/containers';
import MrClaimAndInvoiceReview from './te006/containers'
// Budget Management Routes
import BudgetPermissionWrapper from './budget_management/components/BudgetPermissionWrapper'
import EngineerPermissionWrapper from './budget_management/components/EngineerPermissionWrapper'
import BudgetExpense from './budget_management/containers/BudgetExpense'
import BudgetAllocation from './budget_management/containers/BudgetAllocation'
import BudgetAnalytics from './budget_management/containers/BudgetAnalytics'

// Task Management Routes
import IssueDetail from './task_management/containers/IssueDetail';
import Issues from './task_management/containers/Issues';
import MilestoneDetail from './task_management/containers/MilestoneDetail';
import Milestones from './task_management/containers/Milestones';
import Stages from './task_management/containers/Stages';
import TaskDetail from './task_management/containers/TaskDetail';
import Tasks from './task_management/containers/Tasks';
import TimeSheet from './task_management/containers/TimeSheet';
import OprTracker from './trackers/containers/OprTracker';
import requireAuth from '../utils/requireAuth'
import Workbook from './t013/containers';
export const projectsRoutes = [
    {
        path:'/technical/opr/report',
        component:requireAuth(OprTracker),
        key:'OprTracker'
    },
    {
        path:'/technical/projects',
        component:requireAuth(ProjectListing),
        key:'ProjectListing'
    },
    {
        path:'/technical/form/templates',
        component:requireAuth(FormTemplates),
        key:'FormTemplates'
    },
    {
        path:'/technical/project/view/:project_slug',
        component:requireAuth(ProjectDetail),
        key:'ProjectDetail'
    },
    {
        path:'/technical/project/view/:project_slug/project-opr-form',
        component:requireAuth(OprForm),
        key:'OprForm'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t001',
        component:requireAuth(GeneralPictures),
        key:'GeneralPictures'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t002',
        component:requireAuth(DataPlates),
        key:'DataPlates'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T003',
        component:requireAuth(DocumentsChecklist),
        key:'DocumentsChecklist'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t004',
        component:requireAuth(ObservationChecklist),
        key:'ObservationChecklist'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/v_t005',
        component:requireAuth(TechnicalSpecification),
        key:'TechnicalSpecification'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T006',
        component:requireAuth(Contacts),
        key:'Contacts'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/v_t007',
        component:requireAuth(PictorialFindings),
        key:'PictorialFindings'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T009',
        component:requireAuth(Robberies),
        key:'Robberies'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T011',
        component:requireAuth(ProblemResolutionSheet),
        key:'ProblemResolutionSheet'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T011/:id',
        component:requireAuth(PRSIssueDetail),
        key:'PRSIssueDetail'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T013',
        component:requireAuth(Workbook),
        key:'DocumentsChecklist'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te001',
        component:requireAuth(BoreScopeInspection),
        key:'BoreScopeInspection'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te002',
        component:requireAuth(PowerPlantInventories),
        key:'PowerPlantInventories'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te003',
        component:requireAuth(GapReports),
        key:'GapReports'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te004',
        component:requireAuth(BuildStandardEvaluation),
        key:'BuildStandardEvaluation'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te005',
        component:requireAuth(TableInspection),
        key:'TableInspection'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te006',
        component:requireAuth(MrClaimAndInvoiceReview),
        key:'MrClaimAndInvoiceReview'
    },
    {
        path:'/technical/project/:project_slug/budget/expense',
        component:requireAuth(BudgetPermissionWrapper(BudgetExpense)),
        key:'BudgetExpense'
    },
    {
        path:'/technical/project/:project_slug/budget/allocation',
        component:requireAuth(BudgetPermissionWrapper(EngineerPermissionWrapper(BudgetAllocation))),
        key:'BudgetAllocation'
    },
    {
        path:'/technical/project/:project_slug/budget/analytics',
        component:requireAuth(BudgetPermissionWrapper(EngineerPermissionWrapper(BudgetAnalytics))),
        key:'BudgetAnalytics'
    },
    {
        path:'/technical/project/:project_slug/stages',
        component:requireAuth(Stages),
        key:'Stages'
    },
    {
        path:'/technical/project/:project_slug/milestones',
        component:requireAuth(Milestones),
        key:'Milestones'
    },
    {
        path:'/technical/project/:project_slug/milestone/:slug',
        component:requireAuth(MilestoneDetail),
        key:'MilestoneDetail'
    },
    {
        path:'/technical/project/:project_slug/tasks',
        component:requireAuth(Tasks),
        key:'Tasks'
    },
    {
        path:'/technical/project/:project_slug/task/:slug',
        component:requireAuth(TaskDetail),
        key:'TaskDetail'
    },
    {
        path:'/technical/project/:project_slug/issues',
        component:requireAuth(Issues),
        key:'Issues'
    },
    {
        path:'/technical/project/:project_slug/issue/:slug',
        component:requireAuth(IssueDetail),
        key:'IssueDetail'
    },
    {
        path:'/technical/project/:project_slug/time-sheet',
        component:requireAuth(TimeSheet),
        key:'TimeSheet'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T014',
        component:requireAuth(T014Nav),
        key:'t014'
    },
]
