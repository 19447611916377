import React, { useState } from "react"
import { useSnackbar } from "notistack"
import { withRouter } from "react-router"
import moment from 'moment';
import { TableRow, TableCell, Tooltip, IconButton } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm"
import { displayDateFormatShort } from "../../../constants"
const StageList = ({ item, onEdit, onRemoveStages, onStageClosure}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [showFullDescription, setShowFullDescription] = useState({})
  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    })
  }
  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell>{item.name ? item.name : ""}</TableCell>
        <TableCell>
          <span className={`stage-status ${item.status.label === 'OPEN' ? 'active':'completed'}`}>
            {item.status && item.status.label == "OPEN" ? "Active" : "Close"}
          </span>
        </TableCell>
        <TableCell>
          {item.expected_start_date ? moment(item.expected_start_date).format(displayDateFormatShort) : ""}
        </TableCell>
        <TableCell>
          {item.expected_end_date ? moment(item.expected_end_date).format(displayDateFormatShort) : ""}
        </TableCell>
        <TableCell>
          {item.actual_start_date ? moment(item.actual_start_date).format(displayDateFormatShort) : ""}
        </TableCell>
        <TableCell>
          {item.actual_end_date ? moment(item.actual_end_date).format(displayDateFormatShort) : ""}
        </TableCell>
        <TableCell className="remarksBox" style={{width:'150px'}}>
          {item.closure_remarks && item.closure_remarks.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.closure_remarks}
                  <a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id)}} >
                  &nbsp;less
                  </a>
                </span>
              ) : (
                <span>
                  {item.closure_remarks.substring(0, 50)}
                  <a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id)}}>
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.closure_remarks && item.closure_remarks.length <= 150 ? (
            <span>{item.closure_remarks}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell className="remarksBox" style={{width:'300px'}}>
          {item.notes && item.notes.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.notes}
                  <a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id)}}>
                  &nbsp;less
                  </a>
                </span>
              ) : (
                <span>
                  {item.notes.substring(0, 150)}
                  <a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id)}}>
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.notes && item.notes.length <=150  ? (
            <span>{item.notes}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>
          {item.status.id !== 2 ?
            <div style={{width:'80px'}}>
              <Tooltip title="Edit" className={ item.status && item.status.label == "CLOSE" ? "hide-btn" : "" } >
                <IconButton size="small" color="primary" onClick={onEdit} >
                  <EditIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Closure" className={ item.status && item.status.label == "CLOSE" ? "hide-btn" : "" } >
                <IconButton size="small" color="primary" onClick={() => onStageClosure(item)} >
                  <AccessAlarmIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" className={ item.status && item.status.label == "CLOSE" ? "hide-btn" : "" } >
                <IconButton size="small" color="primary" onClick={() => onRemoveStages(item)} >
                  <DeleteIcon color="secondary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>:null
          }
        </TableCell>
      </TableRow>
    </>
  )
}
export default withRouter(StageList)
