import React, { Fragment, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText, Checkbox, ListItemSecondaryAction, CircularProgress, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, onCheckPermission } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
const ProjectUserPopUp = ({params, project, memberIds, label, userType, operator_level, getResponseBack}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const getUserTypes = () => {
    globalGetService(`technical/users/`,{role:userType})
    .then(response => {
      if(checkApiStatus(response)){
        setUsers(response.data.data);
        setModal(true)
      }      
    })
  }
  const selectProjectUserFn = (flag, id) => {
    if(flag){
      setSelectedUsers([...selectedUsers, id])
    }else{
      setSelectedUsers(selectedUsers.filter(item => item !== id))
    }
  }
  const onAddProjectUser = () => {
    const addUsers = users.filter(item => selectedUsers.includes(item.id)).map(u => {return{user_id:u.id, project_role:u.project_role, operator_level:userType === 'member' ?operator_level:null}})
    setLoading(true);
    globalPostService(`technical/project/${params.project_slug}/add-user/`, {add_user_list:addUsers})
    .then(response => {
      setLoading(false);
        if(checkApiStatus(response)){
            enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
            setModal(false);setUsers([]);setSearch('');            
            getResponseBack();
            trackActivity('Project Management', {
              event_type: 'Add Project User Success',
              page_title: 'Project Detail',
              add_user_list: addUsers ? addUsers : '',
              project_slug: params.project_slug ? params.project_slug : '',
              user_type: userType ? userType : '',
              operator_level: operator_level ? operator_level : '',
              success_msg: response.data.message
            });
        }else{
            enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}});
            trackActivity('Project Management', {
              event_type: 'Add Project User Failed',
              page_title: 'Project Detail',
              project_slug: params.project_slug ? params.project_slug : '',
              user_type: userType ? userType : '',
              operator_level: operator_level ? operator_level : '',
              error_msg: response.data.message
            });
        }
    })
}
  return(
    <Fragment>
      { onCheckPermission('technical', 'projects','U') && project.archive_status !==1 && (project.status && project.status.id && ![7,8].includes(project.status.id)) ? 
        <Button onClick={() => {getUserTypes(); setSelectedUsers([]); trackActivity('Project Management', { event_type:'Clicked on Add Button', page_title:'Project Detail', user_type: userType ? userType : ''})}} variant="outlined" size="small" color="primary" startIcon={<AddIcon />}>ADD</Button>:null}
      { modal ?
        <Dialog
          maxWidth={'md'}
          open={modal}
          onClose={() => {setModal(false);setUsers([]); setSelectedUsers([])}}
          aria-labelledby="scroll-dialog-title"
          className='project-management-modal'
        >
          <DialogTitle id="scroll-dialog-title">
            Select {label}
          </DialogTitle>
          <DialogContent dividers={true}>
            <div style={{width:'320px'}}>
              <TextField
                  id="search"
                  placeholder='Search by Name'
                  fullWidth
                  margin="normal"
                  value={search}
                  InputLabelProps={{shrink: true}}
                  inputProps={{maxLength: 30}}
                  onChange={(e) => setSearch(e.target.value)}
                  variant='outlined'
              />
              <List>
                {(() => {
                  let userList = users.filter(item => !memberIds.includes(item.id));
                  userList = search.trim().length ? userList.filter(user => user.name.toLowerCase().includes(search.toLowerCase())) : userList
                  if(userList.length){
                    return (
                      userList.map((item, index) =>
                        <ListItem key={index} role={undefined} dense button>
                          <ListItemAvatar>
                            <Avatar
                              alt=''
                              src={item.profile_pic}
                            />
                          </ListItemAvatar>
                          <ListItemText id={item.id} primary={item.name} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              color="primary"
                              edge="end"
                              onChange={(e) => selectProjectUserFn(e.target.checked ,item.id)}
                              checked={selectedUsers.indexOf(item.id) !== -1}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    )
                  }else{
                    return(
                      <div className='text-center'>No user(s) found</div>
                    )
                  }
                })()}
              </List>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setModal(false);setUsers([]); setSelectedUsers([]);trackActivity('Project Management', { event_type:'Clicked on Cancel Button', page_title:'Project Detail', user_type: userType ? userType : ''})}} color="primary" size="small">Cancel</Button>
            <Button disabled={isLoading || selectedUsers.length === 0 ? true:false} variant="contained" onClick={onAddProjectUser} color="primary" size="small">
              {isLoading ? <CircularProgress size={20} color="secondary" /> : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>:null
      }
    </Fragment>
  )
}
export default withRouter(ProjectUserPopUp);
