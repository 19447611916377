import React, { Fragment, useState } from "react"
import { withRouter } from "react-router"
import { useSnackbar } from "notistack"
import moment from 'moment';
import { Menu, MenuItem, TableRow, TableCell, Tooltip, IconButton, LinearProgress, withStyles, Box, TextField, Select, Chip } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { Autocomplete } from "@material-ui/lab"
import { displayDateFormatShort } from "../../../constants"
const MilestoneStatus = ({item, statusDropdown, changeStatus}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (status) => {
    handleClose();
    changeStatus({id:status.value,label:status.label}, item.id)
  };
  return(
    <Fragment>
        <span onClick={(event) => setAnchorEl(event.currentTarget)} className={`milestone-status ${item.status.label.toLowerCase()}`}>
          <span className="flex-centered">
            {item.status.label}
            <ArrowDropDownIcon fontSize="small" />
          </span>
        </span>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {statusDropdown.map((item) => 
            <MenuItem onClick={() => handleClick(item)}>{item.label}</MenuItem>
          )}
      </Menu>
    </Fragment>
  )
}
const MilestoneList = ({ item, onRemoveMilestones, editMilestones, changeStatus, statusDropdown}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [showFullDescription, setShowFullDescription] = useState({})
  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    })
  }
  const StyledChip = withStyles({
    root: {
      position: "relative",
    },
    icon: {
      position: "absolute",
      right: 0,
      marginRight: "0px",
      color: "white",
    },
  })(Chip)
  const BorderLinearProgress = withStyles({
    root: {
      height: "13px",
      width: "54px",
      backgroundColor: "#D9D9D9",
      borderRadius: "10px",
    },

    bar: {
      borderRadius: 20,
      backgroundColor: "#74CB80",
    },
  })(LinearProgress)

  let taskValue = item.tasks ? (item.tasks.closed * 100) / item.tasks.total : 0
  let issuesValue = item.issues
    ? (item.issues.closed * 100) / item.issues.total
    : 0
  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell>
          <div style={{width:'140px'}}>{item.name}</div>
        </TableCell>
        <TableCell id="statusDropdown">
          <MilestoneStatus item={item} statusDropdown={statusDropdown.filter(st => st.label !== item.status.label)} changeStatus={changeStatus} />
        </TableCell>
        <TableCell>{item.progress}%</TableCell>
        <TableCell>
          <div style={{width:'120px'}}>
            {item.owner ? item.owner.name : "--"}
          </div>
        </TableCell>
        <TableCell>
          <div style={{width:'100px'}}>
            {item.start_date ? moment(item.start_date).format(displayDateFormatShort):'--'}
          </div>
        </TableCell>
        <TableCell>
          <div style={{width:'100px'}}>
            {item.end_date ? moment(item.end_date).format(displayDateFormatShort):'--'}
          </div>
        </TableCell>
        <TableCell>
          <div style={{width:'120px'}}>
            {item.project_stage ? item.project_stage.name : "--"}
          </div>
        </TableCell>
        <TableCell>
          <div style={{width:'120px'}}>
            {item.work_order ? item.work_order.unique_name : "--"}
          </div>
        </TableCell>
        <TableCell>
          <Box
            position="relative"
            display="inline-flex"
            style={{ width: "100%", alignItems: "center" }}
          >
            <span className="progressLabel">
              {item.tasks ? item.tasks.closed : "--"}
            </span>
            <BorderLinearProgress
              variant="determinate"
              value={isNaN(taskValue) ? (taskValue = 0) : taskValue}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Box>
            <span className="progressLabel">
              {item.tasks ? item.tasks.total : "--"}
            </span>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            position="relative"
            display="inline-flex"
            style={{ width: "100%", alignItems: "center" }}
          >
            <span className="progressLabel">
              {item.issues ? item.issues.closed : "--"}
            </span>
            <BorderLinearProgress
              variant="determinate"
              value={isNaN(issuesValue) ? (issuesValue = 0) : issuesValue}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Box>
            <span className="progressLabel">
              {item.issues ? item.issues.total : "--"}
            </span>
          </Box>
        </TableCell>
        <TableCell className="remarksBox">
          {item.remarks && item.remarks.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.remarks}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.remarks.substring(0, 50)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.remarks && item.remarks.length <= 150 ? (
            <span>{item.remarks}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>
          <div style={{width:'70px'}}>
            <Tooltip title="Edit">
              <IconButton size="small" color="primary" onClick={editMilestones} >
                <EditIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton size="small" color="primary" onClick={() => onRemoveMilestones(item)} >
                <DeleteIcon color='secondary' fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}
export default withRouter(MilestoneList)
