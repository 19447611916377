import { getLocalStorageInfo } from '../../utils_v2';
export const budgetAccessGroup = ['SPARTA Administrator', 'Lessor Administrators', 'Project Administrator', 'Project Lead - Acumen', 'Project Lead - Client'];
export const budgetAccessEngineer = ['Project Engineer - Acumen', 'Project Engineer - Client'];

export const allocationHd = [
    {label:'Sl.No.', sortOption:false},
    {label:'Ref. No.', sortOption:false},
    {label:'Asset', sortOption:false},
    {label:'Allocation Category', sortOption:false},
    {label:'Allocation Sub-Category', sortOption:false},
    {label:'Budgeted Amount', sortOption:false},
    {label:'Variance(%)', sortOption:false},
    {label:'Remarks', sortOption:false},
    {label:'Actions', sortOption:false, actionCell:true}
];
export const expenseHd = [
    {label:'Sl.No.', sortOption:false},
    {label:'Ref. No.', sortOption:false},
    {label:'Asset', sortOption:false},
    {label:'Category', sortOption:false},
    {label:'Sub Category', sortOption:false},
    {id:'status', label:'Status', sortOption:true},
    {id:'user',label:'Raised For', sortOption:false},
    {id:'approver',label:'Approver', sortOption:false},
    {id:'from_date',label:'Date From', sortOption:true},
    {id:'to_date', label:'Date To', sortOption:true},
    {label:'Location', sortOption:false},
    {id:'bill_amount',label:'Bill Amount', sortOption:true},
    {label:'Conversion Rate', sortOption:false},
    {id:'amount', label:'Expense Amount', sortOption:true},
    {id:'approved_amount', label:'Approved Amount', sortOption:true},
    {label:'Remarks', sortOption:false},
    {label:'Attachments', sortOption:false},
    {label:'Actions', sortOption:false, actionCell:true}
];
export const allocationFilterOptions = {
    'asset': {
        'inputType': 'dropdown',
        'placeholder': 'Search by Asset',
        'title': 'Asset',
        'options':[],
        'labelKey':'name',
        'valueKey':'unique_id',
    },
    'category': {
        'inputType': 'dropdown',
        'placeholder': 'Search by Category',
        'title': 'Category',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
    }
}
export const expenseFilterOptions = {
    'asset': {
        'inputType': 'dropdown',
        'placeholder': 'Search by Asset',
        'title': 'Asset',
        'options':[],
        'labelKey':'name',
        'valueKey':'unique_id',
    },
    'category': {
        'inputType': 'dropdown',
        'placeholder': 'Search by Category',
        'title': 'Category',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
    },
    'user': {
        'inputType': 'dropdown',
        'title': 'Raised For',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple': true
    },
    'approver': {
        'inputType': 'dropdown',
        'title': 'Approver',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple': true
    },
    'status': {
        'inputType': 'dropdown',
        'placeholder': 'Search by Status',
        'title': 'Status',
        'options':[{label:'Pending', value:'0'}, {label:'Approved', value:'1'}, {label:'Rejected', value:'2'}, {label:'Paid', value:'4'}],
        'labelKey':'label',
        'valueKey':'value',
        'multiple': true
    },
    'from_date':{
        'inputType': 'date',
        'title': 'From Date'
    },
    'to_date':{
        'inputType': 'date',
        'title': 'To Date'
    }
};
export const allocationObj = {
    category: null,
    sub_category: null,
    alloted_amount: '',
    variance: '',
    remarks: ''
};
export const expenseObj = {
    asset:null,
    category: null,
    sub_category: null,
    user: null,
    bill_amount: '',
    from_date: null,
    to_date: null,
    location: '',
    bill_amount: '',
    currency: null,
    conversion_rate:'',
    amount_currency:null,
    amount:'',
    remarks:'',
    files:[]
}

export const globalCurrency = [
    {
		symbol: "$",
		name: "US Dollar",
		code: "USD"
	},
    {
		symbol: "CA$",
		name: "Canadian Dollar",
		code: "CAD"
	},
    {
		symbol: "€",
		name: "Euro",
		code: "EUR"
	},
    {
		symbol: "Rs",
		name: "Indian Rupee",
		code: "INR"
	}
];

export const checkBudgetPermission = (group) => {
    const userInfo = getLocalStorageInfo();
    const securityGroups = userInfo.user.security_groups.map(item => item.name);
    const isAccess = group.some(r=> securityGroups.includes(r));
    return true;
    return isAccess;
}