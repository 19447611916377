import React, { Fragment, Component, useState, useEffect } from 'react';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText, Checkbox, ListItemSecondaryAction, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
const AssignEngineer = ({users, engineerAssigned, onSelectAllForms, toggleModalFn, assignUserToForm, selectProjectEngineerFn, engineerAssignedLoader}) => {
  return(
    <Dialog
        maxWidth={'md'}
        open={engineerAssigned.modal}
        onClose={toggleModalFn}
        aria-labelledby="scroll-dialog-title"
        className='project-management-modal'
    >
        <DialogTitle id="scroll-dialog-title">
            Assign Engineer
        </DialogTitle>
        <DialogContent dividers={true}>
        <List>
            {(() => {
                return (
                    users.map((item, index) =>
                    <ListItem key={index} role={undefined} dense button>
                        <ListItemAvatar>
                            <Avatar alt='' src={item.profile_pic} />
                        </ListItemAvatar>
                        <ListItemText id={item.id} primary={item.name} />
                        <ListItemSecondaryAction>
                        <Checkbox
                            color="primary"
                            edge="end"
                            onChange={(e) => selectProjectEngineerFn(e.target.checked ,item)}
                            checked={engineerAssigned.selectedEngineer && engineerAssigned.selectedEngineer.id === item.id? true : false}
                        />
                        </ListItemSecondaryAction>
                    </ListItem>
                    )
                )
            })()}
        </List>
        </DialogContent>
        <DialogActions>
            <div>
                <div style={{float:'right'}}>
                    <Button onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                    <Button disabled={engineerAssigned.selectedEngineer === null  || engineerAssignedLoader ?true:false} variant="contained" onClick={assignUserToForm} color="primary" size="small">
                        {engineerAssignedLoader ? <CircularProgress size={24} /> : 'Save' }
                    </Button>
                </div>
                {engineerAssigned.selectedEngineer ?
                    <p style={{clear:'both'}}>
                        <Checkbox checked={engineerAssigned.assign_to_all} onChange={(e) => onSelectAllForms(e.target.checked)} color='primary' size="small" /> 
                        Assign to all Form
                    </p>:null
                }
            </div>
        </DialogActions>
    </Dialog>
  )
}
export default AssignEngineer;
