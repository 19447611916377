import React, { Fragment } from "react";
import { Link, withRouter } from 'react-router';
import ProjectDetailTab from '../../projects/components/ProjectDetailTab';
import { Paper } from "@material-ui/core";
const TaskManagementWrapper = ({location, params, children, handleTabChange}) => {
    return (
        <div>
            <ProjectDetailTab tabIndex={'task-management'} handleTabChange={() => console.log('')} />
            <Paper className="tasks-management-section">
                <div className="sidebar-menu">
                    <div className="menu-list">
                        <Link activeClassName="active" to={`/technical/project/${params.project_slug}/stages`}>Stages</Link>
                    </div>
                    <div className="menu-list">
                        <Link activeClassName="active" to={`/technical/project/${params.project_slug}/milestones`}>Milestones</Link>
                    </div>
                    <div className="menu-list">
                        <Link className={location.pathname.includes('task')?'active':''} to={`/technical/project/${params.project_slug}/tasks`}>Tasks</Link>
                    </div>
                    <div className="menu-list">
                        <Link className={location.pathname.includes('issue')?'active':''} to={`/technical/project/${params.project_slug}/issues`}>Issues</Link>
                    </div>
                </div>
                <div className="tasks-management-content" style={{minHeight: window.innerHeight-110}}>
                    <div style={{padding:'15px'}}>
                        {children}
                    </div>
                </div>
            </Paper>
        </div>
    );
}
export default withRouter(TaskManagementWrapper);