import React from 'react';
import { Avatar, Tooltip, Paper } from '@material-ui/core';
import ProjectUserPopUp from './ProjectUserPopUp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { onCheckPermission } from '../../../utils_v2';
const ProjectUsers = ({project, label, memberIds, userType, operator_level, users, onRemoveUser, getResponseBack}) => {
  return(
    <Paper className="work-order-users">
      <div className='user-type flex-centered'>
        <h4>{label}</h4>
        <ProjectUserPopUp 
          label={label}
          userType={userType}
          operator_level={operator_level}
          getResponseBack={getResponseBack}
          memberIds={memberIds}
          project={project}
        />
      </div>
      {
        <ul className="list-unstyled">
          {users.length ? users.map((user, index) =>
            <li key={index} className="flex-centered">
              <Avatar className="" src={user.profile_pic} />
              <div className="user-details">
                <h5 className="flex-centered">
                  {user.name}
                  { onCheckPermission('technical','projects','U') && project.archive_status !==1 && (project.status && project.status.id && ![7,8].includes(project.status.id)) ?
                    <Tooltip arrow title="Remove">
                      <DeleteOutlineIcon onClick={() => onRemoveUser(user)} color="error" fontSize="small" />
                    </Tooltip>:null
                  }
                  
                </h5>
                <p>{user.designation}</p>
              </div>
            </li>
          ):
          <li style={{textAlign:'center'}}>
            No {label} added yet!
          </li>
          }
        </ul>
      }
    </Paper>
  )
}
export default ProjectUsers;
