import React, { Fragment, useState } from 'react';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { Link } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteOutlined } from '@material-ui/icons';
import { RevisionHistory } from '../../../shared_elements'
import { onCheckPermission } from '../../../utils_v2';
import { getLocalStorageInfo } from '../../../utils';
const AllocationList = ({index, item, onEditBudget, onDeleteBudget, project}) => {
  const [open, setOpen] = useState(false);
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{index+1}</TableCell>
      <TableCell>#{item.id}</TableCell>
      <TableCell>{item.asset && item.asset.name ? item.asset.name:'-'}</TableCell>
      <TableCell>{item.category && item.category.name ? item.category.name:''}</TableCell>
      <TableCell>{item.sub_category && item.sub_category.name ? item.sub_category.name:''}</TableCell>
      <TableCell>
        { project.currency ? <span style={{padding:'0 2px 0 0'}}>{project.currency}</span> :''}
        <NumericLabel params={{justification:'L',precision:2,commafy:true}}>{item.alloted_amount ? item.alloted_amount:0}</NumericLabel>
      </TableCell>
      <TableCell>{item.variance ? item.variance:''}</TableCell>
      <TableCell style={{width:'300px'}}>
          {item.remarks ? item.remarks.trim().length > 50 ? open ? item.remarks:item.remarks.slice(0, 47)+' ... ': item.remarks :''}
          {item.remarks.trim().length > 50 ?
            <Fragment>
              { open ? <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setOpen(false)}>Show Less</span>: <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setOpen(true)}>Show More</span>}
            </Fragment>:null
          }
      </TableCell>
      <TableCell className='actions-cell' style={{width:'80px'}}>
        <RevisionHistory buttonType={true} url='audit/technical/tcbudgetallocations/' queryParams={{action:1,object_id:item.id}} />
        {onCheckPermission('technical', 'budget_allocation', 'U') || getLocalStorageInfo().user.security_groups.filter(group => [29,24,23,21,10].includes(group.id)).length ?
          <span>
            <Tooltip title='Edit' arrow>
              <EditIcon onClick={onEditBudget} color='primary' fontSize='small' />
            </Tooltip>
          </span>
          : null}
        {onCheckPermission('technical', 'budget_allocation', 'D') || getLocalStorageInfo().user.security_groups.filter(group => [29,24,23,21,10].includes(group.id)).length ?
          <span>
            <Tooltip title='Delete' arrow>
              <DeleteOutlined onClick={onDeleteBudget} color="secondary" fontSize='small' />
            </Tooltip>
          </span> 
        : null}
      </TableCell>
    </TableRow>
  )
}
export default AllocationList;