import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from "notistack";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {DropzoneArea} from 'material-ui-dropzone';
import {DeletePopUp,PageLoader} from '../../../shared_elements';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, getLocalStorageInfo } from "../../../utils_v2";
import { trackActivity } from '../../../utils/mixpanel';
const Attachments = ({params, cardType}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoading, setLoading] = useState(false);
    const [uploadAttachment, setUploadAttachment] = useState({modal:false, files:[]})
    const [attachments, setAttachments] = useState([]);
    const [deleteAttachment, setDeleteAttachment] = useState({modal:false, attachment:null});
    useEffect(() => {
        getAttachments({});  
    }, []);
    const getAttachments = () => {
        globalGetService(`technical/project/${params.project_slug}/attachments/${cardType === 'issue'?'issue':'task'}/${params.slug}/`, {view:'all'})
        .then(response => {
            if(checkApiStatus(response)){
                setAttachments(response.data.data)
            }
        })
    }
    const onDeleteAttachment = () => {
        setLoading(true)
        globalDeleteService(`technical/project/${params.project_slug}/attachments/${deleteAttachment.attachment.id}/`)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                setDeleteAttachment({modal:false, attachment:null});
                setAttachments(attachments.filter((item) => item.id !== deleteAttachment.attachment.id))
                trackActivity('Task Management', {
                    event_type: 'Delete Attachment Success',
                    page_title: 'Delete Attachment',
                    project_slug: params.project_slug,
                    attachment_id: deleteAttachment.attachment.id ? deleteAttachment.attachment.id : '',
                    response_msg: response.data.message
                  })
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
                trackActivity('Task Management', {
                    event_type: 'Delete Attachment Failed',
                    page_title: 'Delete Attachment',
                    project_slug: params.project_slug,
                    attachment_id: deleteAttachment.attachment.id ? deleteAttachment.attachment.id : '',
                    response_msg: response.data.message
                  })
            }
        })
    }
    const onUploadAttachment = () => {
        if(uploadAttachment.files.length === 0){
            enqueueSnackbar("Please uplaod Attachement ", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
        }else{
        setLoading(true)
        let formData = new FormData();
        for(let i=0; i<uploadAttachment.files.length;i++){
            formData.append("file" + (i+1), uploadAttachment.files[i])
        }
        globalPostService(`technical/project/${params.project_slug}/attachments/${cardType === 'issue'?'issue':'task'}/${params.slug}/`,formData)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }});
                setUploadAttachment({modal:false, files:[]});
                setAttachments([...attachments, ...response.data.data]);
                trackActivity('Task Management', {
                    event_type: 'Upload Attachment Success',
                    page_title: 'Upload Attachment',
                    project_slug: params.project_slug,
                    request_body: formData ? formData : '',
                    response_msg: response.data.message
                  })
            }else{
                enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }});
                trackActivity('Task Management', {
                    event_type: 'Upload Attachment Failed',
                    page_title: 'Upload Attachment',
                    project_slug: params.project_slug,
                    request_body: formData ? formData : '',
                    response_msg: response.data.message
                  })
            }
        })
     }
    }   
    return(
        <div className='task-management-attachments'>
            <ul className='list-inline attachment-blk'>
                { attachments.map((item) =>
                    {
                        const extension = item.file_name.split('.').pop().toLowerCase();
                        if(['jpg', 'jpeg', 'png', 'gif'].includes(extension)){
                            return(
                                <li className='attachment-list list-inline-item' style={{width:'150px'}}>
                                    <div className='attachment'>
                                        <img style={{width:'150px', height:'120px'}} src={item.url} alt="" />
                                    </div>
                                    <p className='file-info flex-centered'>
                                        <span>{item.file_name.length > 15 ? item.file_name.slice(0,15)+'...' : item.file_name.length}</span>
                                        <ul className='list-inline'>
                                            <li className='list-inline-item'>
                                                <a href={item.url} download>
                                                    <SaveAltIcon color='primary' fontSize='small' />
                                                </a>
                                            </li>
                                            { item.created_by &&  item.created_by.id ?
                                                getLocalStorageInfo().user.id === item.created_by.id ?
                                                <li className='list-inline-item'>
                                                    <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, attachment:item})} color='secondary' fontSize='small' />
                                                </li>:null:
                                                <li className='list-inline-item'>
                                                    <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, attachment:item})} color='secondary' fontSize='small' />
                                                </li>
                                            }
                                        </ul>
                                    </p>
                                </li>
                            )
                        }else{
                            return(
                                <li className='attachment-list list-inline-item' style={{width:'150px'}}>
                                    <div className='attachment' style={{width:'150px', height:'120px'}}>
                                        <span className='extension'>{extension.toUpperCase()}</span>
                                    </div>
                                    <p className='file-info flex-centered'>
                                        <span>{item.file_name.length > 15 ? item.file_name.slice(0,15)+'...' : item.file_name.length}</span>
                                        <ul className='list-inline'>
                                            <li className='list-inline-item'>
                                                <a href={item.url} download>
                                                    <SaveAltIcon color='primary' fontSize='small' />
                                                </a>
                                            </li>
                                            { item.created_by &&  item.created_by.id ?
                                                getLocalStorageInfo().user.id === item.created_by.id ?
                                                <li className='list-inline-item'>
                                                    <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, attachment:item})} color='secondary' fontSize='small' />
                                                </li>:null:
                                                <li className='list-inline-item'>
                                                    <DeleteOutlineIcon onClick={() => setDeleteAttachment({modal:true, attachment:item})} color='secondary' fontSize='small' />
                                                </li>
                                            }
                                        </ul>
                                    </p>
                                </li>
                            )
                        }
                        
                    }
                )}
            </ul>
            <div>
                <Button onClick={() => setUploadAttachment({modal:true, files:[]})} color='primary' variant='outlined' size='small'>Add Attachment</Button>
            </div>   
            {deleteAttachment.modal ? (
                <DeletePopUp
                    modal={deleteAttachment.modal}
                    toggleModalFn={() => setDeleteAttachment({modal:false, attachment:null})}
                    title="Delete Attachment"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteAttachment()}
                    confirmText="Delete"
                />
            ) : null}
            { uploadAttachment.modal ?
                <Dialog
                    open={uploadAttachment.modal}
                    onClose={() => setUploadAttachment({modal:false, files:[]})}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Upload Attachment(s)
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div>
                            <DropzoneArea
                                filesLimit={10}
                                maxFileSize={131072000}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={ true }
                                dropzoneText={<p>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                clearOnUnmount={true}
                                onChange={(files) => setUploadAttachment({...uploadAttachment, files:files})}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setUploadAttachment({modal:false, files:[]})} color="primary" variant='outlined' size='small'>Cancel</Button>
                        <Button disabled={isLoading} onClick={onUploadAttachment} color="primary" variant='contained' size='small'>
                            {isLoading ? <CircularProgress size={20} /> : 'Upload'}
                        </Button>
                    </DialogActions>
                </Dialog>:null
            }
            {isLoading ? <PageLoader />:null}
        </div>
    )
}
export default withRouter(Attachments)