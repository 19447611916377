import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button, CircularProgress } from '@material-ui/core/';
import { withRouter } from 'react-router'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DropzoneArea} from 'material-ui-dropzone';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CkEditorText } from '../../../shared_elements'
import { fieldDateFormat, backendDateFormat } from '../../../constants'
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPostService, globalPutService } from '../../../utils_v2/globalApiServices';
import { addNewIssueObj } from '../'
import moment from 'moment';
import { castleAirInstance } from '../../../shared_elements/components';
const AddIssue = ({params, addIssue, toggleModalFn,getResponseBack,checklist_id, workbook_id, form11Data}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [issue, setIssue] = useState(addIssue.data && addIssue.data.id ? addIssue.data:addNewIssueObj);
  const [assignee, setAssignee] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState({});
  const [warningModal, setWarningModal] = useState({});
  const onFieldChange = (keyParam, value) => {
    setIssue({...issue, [keyParam]:value});
  }
  const onResetErrorKey = (key) => {
    setError({...error,[key]:''})
  }
  useEffect(() => {
    getAssignee();
    getCategories();
  },[]);
  const getAssignee = () => {
    globalGetService(`technical/workorder/${params.workOrderSlug}/project-users/?asset_type=${params.type}`)
    .then(response => {
      if(checkApiStatus(response)){
        setAssignee(response.data.data)
      }
    })
  }
  const getCategories = () => {
    globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report-sections/?asset_type=${params.type}`)
    .then(response => {
      if(checkApiStatus(response)){
        setCategories(response.data.data.list.map((item) => {return{value:item.id,label:item.name}}))
      }
    })
  }
  const onSubmitForm = () => {
    let validationInputs = {
      title: issue.title ? '' : 'Please enter Title',
      description: issue.description ? '' : 'Please enter Description',
      category: issue.category ? '' : 'Please select Category',
      status: issue.status ? '' : 'Please select Status'
    };
  
    if (issue.started_date && issue.estimated_completion_date) {
      if (!moment(issue.estimated_completion_date).isAfter(issue.started_date)) {
        validationInputs.estimated_completion_date = 'End Date should be greater than Start Date';
      }
    }
  
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      let payload = {
        ...issue,
        priority: issue?.priority?.value,
        assignee: issue?.assignee?.id,
        gap_report_section: issue?.category
      };
      if (checklist_id !== undefined) {
        payload.checklist_id = checklist_id;
      }
      if (workbook_id !== undefined) {
        payload.workbook_id = workbook_id;
      }
      if((workbook_id && onCheckFormPermission(form11Data) && !warningModal?.modal ) || !workbook_id || warningModal?.modal){
      setLoading(true);
      globalPostService(`technical/workorder/${params.workOrderSlug}/gap-report/?asset_type=${params.type}`, payload)
        .then(response => {
          setLoading(false);
          if (checkApiStatus(response)) {
            toggleModalFn();
            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
            getResponseBack();
  
            if (issue.attachment && issue.attachment.length) {
              let formData = new FormData();
              formData.append('gap_report_section', issue.category.label);
              for (let i = 0; i < issue.attachment.length; i++) {
                formData.append('files', issue.attachment[i]);
              }
  
              globalPutService(`technical/workorder/${params.workOrderSlug}/gap-report/${response.data.data.id}/?asset_type=${params.type}`, formData)
                .then(response => {
                  if (checkApiStatus(response)) {
                    window.location.reload(); 
                  }
                });
            } else {
              window.location.reload(); 
            }
          } else {
            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
          }
        });
      }
      else{
        setWarningModal({modal:true})
      }
    } else {
      setError(validationInputs);
    }
  };
  console.log('form11Data',params?.type== "aircraft");
  return(
    <>
    <Dialog
      open={addIssue.modal}
      onClose={toggleModalFn}
      className='project-management-modal'
    >
      <DialogTitle>Add Issue</DialogTitle>
      <DialogContent dividers={true}>
        <form>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <TextField 
                required
                id='title'
                label='Issue Title'
                value={issue.title ? issue.title : ''}
                fullWidth
                margin="normal"
                error={error.title}
                helperText={error?.title||''}
                onChange={(e) => onFieldChange('title', e.target.value)}
                InputLabelProps={{shrink: true}}
                variant='outlined'
                onFocus={() => onResetErrorKey('title')}
              />
            </Grid>
            <Grid item md={12}>
              <label className='for-label'>Description<sup>*</sup></label>
              <CkEditorText 
                htmlData={issue.description ? issue.description : ''}
                onChangeData={(data) =>{ onFieldChange('description', data); onResetErrorKey('description')}}
              />
              {error.description ? <p className='error-msg'>{error.description}</p>:null}
            </Grid>
            <Grid item md={6}>
              <Autocomplete
                  options = {categories}
                  getOptionLabel={option => option.label}
                  id="category"
                  value={issue.category? issue.category : null}
                  onChange={(e, value) => onFieldChange('category', value)}
                  renderInput={params => <TextField error={error.category} helperText={error?.category || ''} required {...params} label="Category" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' onFocus={() => onResetErrorKey('category')} />}
              />
            </Grid>
            
            <Grid item md={6}>
              <Autocomplete
                  options = {[{label:'Open',value:'0'},{label:'Closed',value:'1'}, {label:'Reopen',value:'2'},{label:'Unresolved',value:'3'}]}
                  getOptionLabel={option => option.label}
                  id="status"
                  value={issue.status? issue.status : null}
                  onChange={(e, value) => onFieldChange('status', value)}
                  renderInput={params => <TextField error={error.status} helperText={error?.status || ''} required {...params} label="Status" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' onFocus={() => onResetErrorKey('status')} />}
              />
            </Grid>
            <Grid item md={6}>
              <Autocomplete
                options = {[{label:'None',value:0},{label:'P1',value:1},{label:'P2',value:2}]}
                getOptionLabel={option => option.label}
                id="priority"
                value={issue.priority? issue.priority : null}
                onChange={(e, value) => onFieldChange('priority', value)}
                renderInput={params => <TextField {...params} label="Priority" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
              />
            </Grid>
            <Grid item md={6}>
              <Autocomplete
                options = {assignee}
                getOptionLabel={option => option.name}
                id="assignee"
                value={issue.assignee? issue.assignee : null}
                onChange={(e, value) => onFieldChange('assignee', value)}
                renderInput={params => <TextField {...params} label="Assignee" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
              />
            </Grid>
            <Grid item md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="started_date"
                    label="Discrepancy Raised on"
                    format={fieldDateFormat}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    value={issue.started_date?issue.started_date:null}
                    onChange={(data, value) => onFieldChange('started_date', data ? moment(data).format(backendDateFormat):data)}
                    inputVariant='outlined'
                  />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                      margin="normal"
                      id="estimated_completion_date"
                      label="Discrepancy Resolved on"
                      format={fieldDateFormat}
                      fullWidth
                      InputLabelProps={{shrink: true}}
                      value={issue.estimated_completion_date?issue.estimated_completion_date:null}
                      onChange={(data, value) => {onFieldChange('estimated_completion_date', data ? moment(data).format(backendDateFormat):data); onResetErrorKey('estimated_completion_date')}}
                      inputVariant='outlined'
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6}>
              <TextField 
                  id='ata'
                  label='ATA'
                  value={issue.ata ? issue.ata : ''}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{shrink: true}}
                  onChange={(e) => onFieldChange('ata', e.target.value)}
                  variant='outlined'
                />
            </Grid>
            {castleAirInstance ? null :
            <Grid item md={6}>
              <TextField 
                  id='cycles_remaining'
                  label='Cycles Remaining'
                  value={issue.cycles_remaining ? issue.cycles_remaining : ''}
                  fullWidth
                  margin="normal"
                  onChange={(e) => onFieldChange('cycles_remaining', e.target.value)}
                  InputLabelProps={{shrink: true}}
                  variant='outlined'
                />
            </Grid>}
            {castleAirInstance && params?.type== "aircraft" ? null:
            <Grid item md={6}>
              <TextField 
                id='part_number'
                label='Part Number'
                value={issue.part_number? issue.part_number : ''}
                fullWidth
                margin="normal"
                onChange={(e) => onFieldChange('part_number', e.target.value)}
                InputLabelProps={{shrink: true}}
                variant='outlined'
              />
            </Grid> }
            {castleAirInstance && params?.type== "aircraft" ? null:
            <Grid item md={6}>
              <TextField 
                id='serial_number'
                label='Serial Number'
                value={issue.serial_number? issue.serial_number : ''}
                fullWidth
                margin="normal"
                onChange={(e) => onFieldChange('serial_number', e.target.value)}
                InputLabelProps={{shrink: true}}
                variant='outlined'
              />
            </Grid>}
            {castleAirInstance ? null :
            <Grid item md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="estimated_completion_date"
                    label="Installed On"
                    format={fieldDateFormat}
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    value={issue.install_date?issue.install_date:null}
                    onChange={(data, value) => onFieldChange('install_date', data ? moment(data).format(backendDateFormat):data)}
                    inputVariant='outlined'
                  />
              </MuiPickersUtilsProvider>
            </Grid>}
            {castleAirInstance ? null :
            <Grid item md={6}>
              <TextField 
                id='position'
                label='Position'
                value={issue.position ?issue.position:''}
                fullWidth
                margin="normal"
                onChange={(e) => onFieldChange('position', e.target.value)}
                InputLabelProps={{shrink: true}}
                variant='outlined'
              />
            </Grid>}

            <Grid item md={12}>
              <label className='for-label'>Requirement Discrepancy</label>
              <CkEditorText 
                htmlData={issue.requirement_remarks? issue.requirement_remarks : ''} 
                onChangeData={(data) => onFieldChange('requirement_remarks', data)}
              />
            </Grid>
            <Grid item md={12}>
            <label className='for-label'>Background Discrepancy</label>
              <CkEditorText 
                htmlData={issue.background_remarks ? issue.background_remarks : ''} 
                onChangeData={(data) => onFieldChange('background_remarks', data)}
              />
            </Grid>
            <Grid item md={12}>
              <DropzoneArea
                  filesLimit={10}
                  maxFileSize={104857600}
                  showPreviewsInDropzone={false}
                  useChipsForPreview={true}
                  showPreviews={ true }
                  dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents OR Click Here </p>}
                  dropzoneClass="drag-drop-cnt"
                  maxWidth="sm"
                  showAlerts={['info','error']}
                  alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                  clearOnUnmount={true}
                  onChange={(files)=> onFieldChange('attachment', files)} 
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
        <Button disabled={isLoading} onClick={onSubmitForm} color='primary' size='small' variant='contained'>
          { isLoading ? <CircularProgress size={24} /> : 'Save' }
        </Button>
      </DialogActions>
    </Dialog>

     <Dialog
      open={warningModal.modal}
      onClose={()=>setWarningModal({})}
      className='project-management-modal'
    >
      <DialogContent dividers={true} style={{textAlign:"center", padding:'30px', width:'400px'}}>
      Please note once the issue is added, T011 form would change back to "Draft" status.
      </DialogContent>
      <DialogActions>
      <Button disabled={isLoading} onClick={()=>setWarningModal({})} color='primary' size='small' variant='outlined'>Cancel</Button>
      <Button disabled={isLoading} onClick={onSubmitForm} color='primary' size='small' variant='contained'>
      { isLoading ? <CircularProgress size={24} /> : 'Continue' }
      </Button>
      </DialogActions>
    </Dialog>
    </>
  )
}
export default withRouter(AddIssue);