import React, { Fragment } from 'react';
import { withRouter, browserHistory } from 'react-router'
import WorkOrderAsset from './WorkOrderAsset';
import { Grid } from '@material-ui/core';
import { dateTransformAllSet } from '../../../utils_v2';
const WorkOrderHd = ({params, project, workOrder, onEditWorkOrder,onDeleteWorkOrder}) => {
  return(
    <Grid container spacing={1} className="work-order-table">
      <Grid item xs={12} md={5}>
        {(() => {
          if (workOrder.asset.type === 1){
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_Aircraft.png"
                serialNo={`MSN ${workOrder.asset.msn}`}
                type={workOrder.asset.aircraft_type}
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 2) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_engine.png"
                serialNo={`ESN ${workOrder.asset.esn}`}
                type={workOrder.asset.engine_type}
                asset={workOrder.asset}
                progress={workOrder.progress}
                workOrder={workOrder}
                onEditWorkOrder={() => browserHistory.push(`/technical/project/${params.project_slug}/engine/edit/${workOrder.slug}`)}
                work_order_number={workOrder.work_order_number}
                project={project}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 3) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_apu.svg"
                serialNo={`APU ${workOrder.asset.serial_number}`}
                type=''
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 4) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_lg.svg"
                serialNo={`LG ${workOrder.asset.serial_number}`}
                type=''
                asset={workOrder.asset}
                progress={workOrder.progress}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }else if (workOrder.asset.type === 5) {
            return(
              <WorkOrderAsset
                icon="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/icon_propeller.svg"
                serialNo={`PROPELLER ${workOrder.asset.serial_number}`}
                type=''
                progress={workOrder.progress}
                asset={workOrder.asset}
                workOrder={workOrder}
                onEditWorkOrder={onEditWorkOrder}
                project={project}
                work_order_number={workOrder.work_order_number}
                onDeleteWorkOrder={onDeleteWorkOrder}
              />
            )
          }
        })()}
      </Grid>
      <Grid item xs={3} md={2}>
        <p>Forms</p>
        <h4>{workOrder.forms.length}</h4>
      </Grid>
      <Grid item xs={6} md={3}>
        <p>Last Activity</p>
        <h4>{dateTransformAllSet(workOrder.updated_at)}</h4>
      </Grid>
      <Grid item xs={3} md={2}>
        <p>Status</p>
        <h4>{workOrder.status.label}</h4>
      </Grid>
    </Grid>
  )
}
export default withRouter(WorkOrderHd)
